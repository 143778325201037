@font-face {
  font-family: "National2";
  font-weight: 700;
  font-style: normal;
  font-display: block;
  src: url("fonts/National2-Bold.ttf") format("ttf"),
  url("fonts/National2-Bold.woff2") format("woff2"),
  url("fonts/National2-Bold.woff") format("woff");
}

@font-face {
  font-family: "National2";
  font-weight: 500;
  font-style: normal;
  font-display: block;
  src: url("fonts/National2-Medium.ttf") format("ttf"),
  url("fonts/National2-Medium.woff2") format("woff2"),
  url("fonts/National2-Medium.woff") format("woff");
}

@font-face {
  font-family: "National2";
  font-weight: normal;
  font-style: normal;
  font-display: block;
  src: url("fonts/National2-Regular.ttf") format("ttf"),
  url("fonts/National2-Regular.woff2") format("woff2"),
  url("fonts/National2-Regular.woff") format("woff");
}

@font-face {
  font-family: "Tiempos Text";
  font-weight: 400;
  font-style: normal;
  font-display: block;
  src: url("fonts/TiemposText-Regular.ttf") format("ttf"),
  url("fonts/TiemposText-Regular.woff2") format("woff2"),
  url("fonts/TiemposText-Regular.woff") format("woff");
}

@font-face {
  font-family: "Tiempos Headline";
  font-weight: 500;
  font-style: normal;
  font-display: block;
  src: url("fonts/TiemposHeadline-Medium.ttf") format("ttf"),
  url("fonts/TiemposHeadline-Medium.woff2") format("woff2"),
  url("fonts/TiemposHeadline-Medium.woff") format("woff");
}

.el {
  font-family: "National2", "Tiempos Text", "Tiempos Headline", sans-serif;
}